import { serverAppConfig, serverTheme } from './server-app-config'
import { AppConfigBase, AppConfigTheme, SiteMetaData } from 'ecosystem'
import manifest from '../public/manifest.json'
import { server } from './custom/api/server'
import { Metadata } from 'next'
import { stdCanonicalFactory } from 'shared-utils'

const typedServerConfig = serverAppConfig as unknown as AppConfigBase
const typedServerTheme = serverTheme as unknown as AppConfigTheme

const siteMetadata: SiteMetaData = {
  title: manifest.name,
  description: manifest.description,
  image: `${typedServerConfig.logoUrl}`,
  url: server!,
  keywords: ['Gasspisen']
}

const appConfig: AppConfigBase & { site: SiteMetaData; theme: AppConfigTheme } = {
  ...typedServerConfig,
  site: siteMetadata,
  theme: typedServerTheme
}

export default appConfig

type MetadataFactory = Metadata & {
  pathname: string
  title?: string
  description?: string
  images?: string[]
  keywords?: string[]
}
export const metadataFactory = ({
  title,
  description,
  images,
  pathname,
  keywords,
  ...rest
}: MetadataFactory): Metadata => {
  // https://github.com/vercel/next.js/issues/35345
  const path = pathname?.replace(/^\/index/, '/')
  const completeUrl = `${siteMetadata.url}${path}`

  return {
    title: `${title || siteMetadata.title} - ${siteMetadata.title}`,
    description: description || siteMetadata.description,
    keywords: [...siteMetadata.keywords, ...(keywords ? keywords : [])],
    openGraph: {
      url: completeUrl,
      type: 'website',
      title: `${title || siteMetadata.title} - ${siteMetadata.title}`,
      description: description || siteMetadata.description,
      images: [siteMetadata.image, ...(images ? images : [])]
    },
    twitter: {
      title: `${title || siteMetadata.title} - ${siteMetadata.title}`,
      description: description || siteMetadata.description,
      card: 'summary_large_image',
      images: [siteMetadata.image, ...(images ? images : [])]
    },
    alternates: {
      canonical: stdCanonicalFactory(path, server)
    },
    ...rest
  }
}
